"use strict";

window.CellContextMenuHandler = {
    get Mixin() {
        return {
            _cellContextMenuHandler: {},
            PRESS_HOLD_DURATION: 25,
            PRESS_HOLD_DURATION_SORTING: 100,
            NEGATIVE_THRESHOLD: 10,
            _shouldPrevent: false,
            _shouldPreventInteraction: function _shouldPreventInteraction(value) {
                this._shouldPrevent = value;

                if (this._shouldPreventInteractionTimer) {
                    clearTimeout(this._shouldPreventInteractionTimer);

                    this._cancelPress();
                }

                if (value) {
                    this._shouldPreventInteractionTimer = setTimeout(function () {
                        this._shouldPrevent = !this._shouldPrevent;
                        this._shouldPreventInteractionTimer = null;
                    }.bind(this), 500);
                }
            },
            _setupListener: function setupListener() {
                this._ctxTimerId = null;
                this._ctxTimerCnt = 0;
                this._countDown = true;
                this._currentZoom = 1;
                this.element.css("--scale-value", 1);
                this._hasTouch = 'ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch;

                if (this._hasTouch) {
                    this.clickableContainer[0].addEventListener("touchstart", this._touchStart.bind(this), false);
                    this.clickableContainer[0].addEventListener("touchmove", this._cancelPress.bind(this), false);
                    this.clickableContainer[0].addEventListener("touchend", this._cancelPress.bind(this), false);
                    window.addEventListener('touchend', this._touchEnd.bind(this), false);
                } else {
                    //this.clickableContainer[0].addEventListener("contextmenu", this._showSortingContextMenu.bind(this));
                }

                this.on("viewWillDisappear", function () {
                    this.element.removeClass("over-darkener");
                    this.delegate.hideSortingContextMenu(this, this.sectionIdx, this);
                }.bind(this));
            },
            _showSortingContextMenu: function _showSortingContextMenu(e) {
                e.preventDefault();

                if (!!NavigationComp.getCurrentActivityType()) {
                    return;
                }

                if (!this.element.hasClass("over-darkener")) {
                    this.element.addClass("over-darkener");
                    this.delegate.showSortingContextMenu(this, this.sectionIdx);
                }
            },
            _touchStart: function _touchStart(e) {
                if (this._shouldPrevent || !!NavigationComp.getCurrentActivityType()) {
                    return;
                }

                if (this.tableView.editSectionsMap[this.sectionIdx] && this.tableView.editSectionsMap[this.sectionIdx].editActive) {
                    return;
                }

                this.ignoreCtxMenu = false;

                if (this.pressTimeout) {
                    clearTimeout(this.pressTimeout);
                    this.pressTimeout = null;
                }

                this.pressTimeout = setTimeout(function () {
                    // Start the timer
                    requestAnimationFrame(this._onPressIncrement.bind(this));
                    e.preventDefault();
                }.bind(this), 300);
            },
            _touchEnd: function _touchEnd() {
                // Stop the timer
                cancelAnimationFrame(this._ctxTimerId);
                this._ctxTimerCnt = 0;
                this._countDown = true;
                this._currentZoom = 1;

                if (this.element) {
                    this.element[0].style.setProperty("--scale-value", 1);
                }
            },
            _onPressIncrement: function _onPressIncrement() {
                if (!this.ignoreCtxMenu) {
                    if (this._ctxTimerCnt < this.PRESS_HOLD_DURATION && this._countDown) {
                        this._ctxTimerId = requestAnimationFrame(this._onPressIncrement.bind(this));
                        this._ctxTimerCnt++;
                        this._currentZoom = 1 - Math.log10(this._ctxTimerCnt) / 20;
                        this.element[0].style.setProperty("--scale-value", this._currentZoom);

                        if (this._ctxTimerCnt > this.NEGATIVE_THRESHOLD) {
                            this._countDown = false;
                        }
                    } else if (this._ctxTimerCnt < this.PRESS_HOLD_DURATION) {
                        this._ctxTimerId = requestAnimationFrame(this._onPressIncrement.bind(this));
                        this._ctxTimerCnt++;
                        this._currentZoom = this._currentZoom + Math.log10(this._ctxTimerCnt) / 200;
                        this.element[0].style.setProperty("--scale-value", this._currentZoom);
                    } else if (this._ctxTimerCnt >= this.PRESS_HOLD_DURATION && this._ctxTimerCnt < this.PRESS_HOLD_DURATION_SORTING) {
                        if (this._ctxTimerCnt === this.PRESS_HOLD_DURATION) {
                            this.element[0].style.setProperty("--scale-value", 1);

                            if (!this.element.hasClass("over-darkener")) {
                                this.element.addClass("over-darkener");
                                this.delegate.showSortingContextMenu(this, this.sectionIdx);
                            }
                        }

                        this._ctxTimerId = requestAnimationFrame(this._onPressIncrement.bind(this));
                        this._ctxTimerCnt++;
                    } else if (this._ctxTimerCnt >= this.PRESS_HOLD_DURATION_SORTING && ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                        if (this.tableView.editSectionsMap[this.sectionIdx] == null || this.tableView.editSectionsMap[this.sectionIdx] && !this.tableView.editSectionsMap[this.sectionIdx].editActive) {
                            this.element.removeClass("over-darkener");
                            this.delegate.hideSortingContextMenu(this, this.sectionIdx, this);

                            this._touchEnd();

                            this.delegate.startSorting(this, this.sectionIdx, this);
                        }
                    } else {
                        this._cancelPress();
                    }
                }
            },
            _cancelPress: function _cancelPress(shouldRemoveOverDarkener) {
                this.ignoreCtxMenu = true;

                if (this.pressTimeout) {
                    clearTimeout(this.pressTimeout);
                    this.pressTimeout = null;
                }

                if (shouldRemoveOverDarkener === true) {
                    this.element.removeClass("over-darkener");
                }

                this._touchEnd();
            }
        };
    }

};
