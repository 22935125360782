"use strict";

window.DelegateHandler = {
    get Mixin() {
        return {
            /**
             * Will call the provided delegate on all registered delegates
             * @param delegateFn
             * @param [args] can either be an array of arguments or one argument
             * @private
             */
            _notifyDelegates: function _notifyDelegates(delegateFn, args) {
                if (this.__delegates) {
                    // Filter out any nulled delegates
                    this.__delegates.filter(function (delegate) {
                        return !!delegate;
                    });

                    this.__delegates.forEach(function (delegate) {
                        if (delegate[delegateFn] && typeof delegate[delegateFn] === "function") {
                            // Ensure args is an Array!
                            if (!(args instanceof Array)) {
                                args = [args];
                            }

                            try {
                                delegate[delegateFn].apply(delegate, args);
                            } catch (e) {
                            }
                        }
                    }.bind(this));
                } else {
                    developerAttention("Missing this.__delegates which is required by the DelegateHandler!");
                }
            }
        };
    }

};
