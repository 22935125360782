/**
 * 1) calls the given promise function
 * 2) hooks between each step (resolve, reject, notify)
 * 3) checks if the "session" is still valid (by calling the checkFn)
 * 4a) valid: calls the correct parameter function (resolve, reject, notify) as like if we never existed
 * 4b) invalid:
 *      1) skips the function calls (resolve, reject, notify)
 *      2) calls the bailout function (resolve, reject)
 *      3) returns a new (never-resolving) promise to stop the chain (resolve, reject)
 * @param promiseFn {function}
 * @param checkFn {function|null}
 * @param resolve {function|null}
 * @param reject {function|null}
 * @param notify {function|null}
 * @param bailout {function|null}
 * @returns {Promise}
 * @private
 */
Q.makePromise.prototype._depPromise = function _depPromise(promiseFn, checkFn, resolve, reject, notify, bailout) {
    if (typeof checkFn !== "function") {
        throw new Error("Promise.depThen needs check function!");
    }

    return promiseFn.call(this, function (args) {
        if (typeof resolve === "function") {
            if (checkFn()) {
                return resolve(args);
            } else {
                //console.info("skipping resolve function");
                bailout && bailout();
                return Q.defer().promise; // return a new promise which will never be resolved/rejected to cancel the chain
            }
        }

        return args;
    }, function (args) {
        if (typeof reject === "function") {
            if (checkFn()) {
                return reject(args);
            } else {
                //console.info("skipping reject function");
                bailout && bailout();
                return Q.defer().promise; // return a new promise which will never be resolved/rejected to cancel the chain
            }
        }

        throw args;
    }, function (args) {
        if (typeof notify === "function") {
            if (checkFn()) {
                notify(args);
            } else {//console.info("skipping notify function");
            }
        }
    });
};
/**
 * creates a new dependent "then" promise which only we be fulfilled while the checkFn returns true
 * @param checkFn {function}
 * @param resolve {function|null}
 * @param [reject] {function|null}
 * @param [notify] {function|null}
 * @param [bailout] {function|null}
 * @returns {Promise}
 */


Q.makePromise.prototype.depThen = function depThen(checkFn, resolve, reject, notify, bailout) {
    return this._depPromise(this.then, checkFn, resolve, reject, notify, bailout);
};
/**
 * creates a new dependent "then" promise which only we be fulfilled while the current ActiveMiniserverSession is still active
 * @param resolve {function|null}
 * @param [reject] {function|null}
 * @param [notify] {function|null}
 * @param [bailout] {function|null}
 * @returns {Promise}
 */


Q.makePromise.prototype.depActiveMsThen = function depActiveMsThen(resolve, reject, notify, bailout) {
    return this.depThen(ActiveMSComponent.getCurrentSessionCheck(), resolve, reject, notify, bailout);
};
/**
 * creates a new dependent "done" promise which only we be fulfilled while the checkFn returns true
 * @param checkFn {function}
 * @param resolve {function|null}
 * @param reject {function|null}
 * @param notify {function|null}
 * @param bailout {function|null}
 * @returns {Promise}
 */


Q.makePromise.prototype.depDone = function depDone(checkFn, resolve, reject, notify, bailout) {
    return this._depPromise(this.done, checkFn, resolve, reject, notify, bailout);
};
/**
 * creates a new dependent "done" promise which only we be fulfilled while the current ActiveMiniserverSession is still active
 * @param resolve {function|null}
 * @param reject {function|null}
 * @param notify {function|null}
 * @param bailout {function|null}
 * @returns {Promise}
 */


Q.makePromise.prototype.depActiveMsDone = function depActiveMsDone(resolve, reject, notify, bailout) {
    return this.depDone(ActiveMSComponent.getCurrentSessionCheck(), resolve, reject, notify, bailout);
};
