"use strict";

window.EventHandler = {
    get Mixin() {
        return {
            //define mixin's prototype. These will be copied to the prototype of the classes that will use this `mixin`
            _eventHandler: {
                eventMap: {}
            },

            /**
             * adds an EventListener to a Event with the given name
             * @param evName name of Event
             * @param listener EventListener function which will be called when this Event is emitted
             * @returns {Function} removeListenerFunction - call this function to remove the EventListener!
             */
            on: function on(evName, listener) {
                if (!(listener instanceof Function)) {
                    throw new Error("The provided 'listener' argument is not a Function");
                }

                var map = this._eventHandler.eventMap,
                    listeners = map[evName] || (map[evName] = []);
                listeners.push(listener);
                return function removeListenerFunction() {
                    // when function got called, remove listener!
                    var listenerIdx = listeners.indexOf(listener);

                    if (listenerIdx !== -1) {
                        listeners.splice(listenerIdx, 1);
                    } else {
                        console.error(new Error("Trying to remove EventListener for Event '" + evName + "' who isn't registered!").stack);
                    }
                };
            },

            /**
             * emits an Event with the given name and various arguments
             * @param evName
             * @param {...*}
             */
            emit: function emit(evName) {
                var map = this._eventHandler.eventMap,
                    listeners = map[evName];

                if (listeners && listeners.length > 0) {
                    var i,
                        eventArgs = Array.prototype.slice.call(arguments, 1);
                    eventArgs.unshift({
                        name: evName,
                        scope: this
                    });

                    for (i = 0; i < listeners.length; i++) {
                        /*setTimeout(function(listener, args) {
                         listener.apply(weakThis, args);                     --> causes problems!
                         }.bind(weakThis, emitListeners[i], eventArgs));*/
                        try {
                            listeners[i].apply(this, eventArgs);
                        } catch (e) {
                            console.error(e.stack);
                        }
                    }
                } else {//console.info("Emitting Event '" + evName + "', but no EventListeners waiting for it");
                }
            },

            /**
             * simply calls the destroyFn and removes the EventListener
             * @param destroyFn
             */
            off: function off(destroyFn) {
                destroyFn();
            },

            /**
             * removes all EventListeners
             */
            destroyEventHandler: function destroyEventHandler() {
                this._eventHandler = {
                    eventMap: {}
                };
            }
        };
    }

};
