"use strict";

var Factory = function () {
    /**
     * a factory - creates new instances, reuses returned instances and caches them
     * @param newInstanceFn gets called, when a new instance is needed
     * @param recycleFn gets called, when an instance is returned, clean up any stuff that may be messed up
     * @param destroyFn gets called, when an instance is going to be destroyed (when the factory is destroyed)
     * @constructor
     */
    function Factory(newInstanceFn, recycleFn, destroyFn) {
        this.newInstanceFn = newInstanceFn;
        this.recycleFn = recycleFn;
        this.destroyFn = destroyFn;
        this.instances = [];
        this.instanceCount = 0;
    }

    /**
     * returns either a cached or a newly created instance
     * @returns {*} instance
     */


    Factory.prototype.getInstance = function getInstance() {
        //console.log("Factory: getInstance", this.instances.length);
        if (this.instances.length) {
            return this.instances.pop();
        } else {
            return this._createNewInstance();
        }
    };
    /**
     * return a used instance when you don't need it any longer!
     * be sure to not use it again, after returning it - otherwise we have a tricky problem :-)
     * @param i instance
     */


    Factory.prototype.returnInstance = function returnInstance(i) {
        //console.log("Factory: returnInstance", this.instances.length + 1);
        this.recycleFn(i);
        this.instances.push(i);
    };
    /**
     * destroys the factory
     */


    Factory.prototype.destroy = function destroy() {
        while (this.instances.length > 0) {
            this._destroyInstance(this.instances.pop());
        }

        this.newInstanceFn = null;
        this.recycleFn = null;
        this.destroyFn = null;
        this.instances = null;
        this.instanceCount = null;
    };
    /**
     * creates a new instance with the newInstanceFn
     * @returns {*} instance
     * @private
     */


    Factory.prototype._createNewInstance = function _createNewInstance() {
        this.instanceCount++; //console.log("Factory: _createNewInstance", this.instanceCount);

        return this.newInstanceFn();
    };
    /**
     * destroys an instance with the destroyFn or <instance>.destroy() - if available
     * @returns {*} instance
     * @private
     */


    Factory.prototype._destroyInstance = function _destroyInstance(i) {
        if (this.destroyFn) {
            this.destroyFn(i);
        } else if (i.destroy) {
            i.destroy();
        }
    };

    return Factory;
}();
