"use strict";

var ConfigVersion = function () {
    var compareOptions = {
        lexicographical: false,
        zeroExtend: true
    };

    function ConfigVersion(vString) {
        if (vString instanceof ConfigVersion) return vString;
        this.version = vString;
    }

    ConfigVersion.prototype.equalTo = function equalTo(v) {
        v = new ConfigVersion(v);
        return versionCompare(this.version, v.version, compareOptions) === 0;
    };

    ConfigVersion.prototype.greaterThan = function greaterThan(v) {
        v = new ConfigVersion(v);
        return versionCompare(this.version, v.version, compareOptions) > 0;
    };

    ConfigVersion.prototype.greaterThanOrEqualTo = function greaterThanOrEqualTo(v) {
        v = new ConfigVersion(v);
        return versionCompare(this.version, v.version, compareOptions) >= 0;
    };

    ConfigVersion.prototype.lessThan = function lessThan(v) {
        v = new ConfigVersion(v);
        return versionCompare(this.version, v.version, compareOptions) < 0;
    };

    ConfigVersion.prototype.lessThanOrEqualTo = function lessThanOrEqualTo(v) {
        v = new ConfigVersion(v);
        return versionCompare(this.version, v.version, compareOptions) <= 0;
    };

    return ConfigVersion;
}();
