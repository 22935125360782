"use strict";

window.StateHandler = {
    get Mixin() {
        return {
            //define mixin's prototype. These will be copied to the prototype of the classes that will use this `mixin`
            //define the constructor of the mixin. This will be automatically called for every instance in the constructor of the class that is using this mixin
            _stateHandler: {
                registerStatesAgain: true,
                statesVersion: -1337,
                stateIds: false,
                ignoreIds: null,
                lastStates: {}
            },

            /**
             * _requestStates is used to ask for an instant snapshot of the current states in a stateContainer. It is performed
             * synchronously since otherwise it would be performed after e.g. subviews did already receive viewWillAppear and
             * therefore mess up the UI management when a subview is to be hidden based on the new states.
             * @param [uuid]        the uuid to register for, default: this.control.uuidAction
             * @param [stateIds]    important if later on stateIds are provided when registering, helps reduce state updates.
             * @private
             */
            _requestStates: function _requestStates(uuid, stateIds) {
                var promises = [true];
                uuid = this.__checkUuidForStates(uuid);
                Debug.StateHandler && console.log(this.__dName(), "_requestStates (ids provided: " + !!stateIds + ")"); // request all initial states once and call receivedStates

                if (this.receivedStates) {
                    var result = SandboxComponent.getStatesForUUID(uuid);

                    if (result && result.version !== this._stateHandler.statesVersion) {
                        try {
                            this.allStatesReceived && promises.push(this.allStatesReceived(true) || true);
                        } catch (e) {
                            console.error(e.stack);
                        }

                        try {
                            promises.push(this.__dispatchStates(result.states, stateIds) || true);
                        } catch (e) {
                            console.error(e.stack);
                        }

                        Debug.StateHandler && console.log("   updating internal version to " + result.version);
                        this._stateHandler.statesVersion = result.version;
                    } else if (!result) {
                        try {
                            this.allStatesReceived && promises.push(this.allStatesReceived(false) || true);
                        } catch (e) {
                            console.error(e.stack);
                        }
                    }
                }

                return Q.all(promises);
            },

            /**
             * Calling this method will ensure receivedStates is called when registering again.
             * @private
             */
            _forceStateUpdate: function _forceStateUpdate() {
                this._stateHandler.statesVersion = -1337;
            },

            /**
             * Registers for all states available for a uuid. Kepps in mind that this instance may already have a set of
             * states at the time it registers. receivedStates will not be called if the
             * existing set is up to date.
             * @param [uuid]        the uuid to register for, default: this.control.uuidAction
             * @param [stateIds]    OPTIONAL, an array containing identifiers for preprocessed states. receivedStates will only be called if one of them changes.
             * @param [ignoreIds]   OPTIONAL, alternative to specify all of interest, rule out those who aren't.
             * @private
             */
            _registerForStates: function _registerForStates(uuid, stateIds, ignoreIds) {
                Debug.StateHandler && console.log(this.__dName(), "_registerForStates (ids: " + !!stateIds + ")");
                uuid = this.__checkUuidForStates(uuid);
                this._stateHandler.ignoreIds = ignoreIds;
                this._stateHandler.stateIds = stateIds;
                this._stateHandler.lastStates = this._stateHandler.lastStates || {}; // when requestStates was used, there may already be lastStates

                if (this.receivedStates && this._stateHandler.registerStatesAgain) {
                    // yes, register for states!
                    this._stateHandler.registerStatesAgain = false;
                    SandboxComponent.registerForStateChangesForUUID(uuid, this, this.__dispatchStates, this.__allStatesReceived, this._stateHandler.statesVersion);
                }
            },

            /**
             * Removes from state-updates for a uuid. Stores the version in order to avoid unneccessary receivedStates-calls
             * when this instance registers again.
             * @param [uuid]  the uuid to register for, default: this.control.uuidAction
             * @private
             */
            _unregisterStates: function _unregisterStates(uuid) {
                Debug.StateHandler && console.log(this.__dName(), "_unregisterStates");
                uuid = this.__checkUuidForStates(uuid);

                if (this.receivedStates && !this._stateHandler.registerStatesAgain) {
                    this._stateHandler.registerStatesAgain = true; // unregister from states

                    SandboxComponent.unregisterForStateChangesForUUID(uuid, this);
                    this._stateHandler.statesVersion = SandboxComponent.getStatesForUUID(uuid).version;
                }
            },

            /**
             * Internal Method to check the uuid and assign the default value this.control.uuidAction. Throws an exception if
             * this control isn't configured & no uuid is provided.
             * @param uuid
             * @returns {*}     either the UUID provided or the uuidAction of this.control
             * @private
             */
            __checkUuidForStates: function __checkUuidForStates(uuid) {
                var result = null;

                if (!uuid && !this.control) {
                    throw new Error("Needs a uuid to register for, remove from or simply get states");
                } else if (!uuid) {
                    result = this.control.uuidAction;
                }

                return result != null ? result : uuid;
            },

            /**
             * Ensures only states of interest are dispatched - if provided
             * @param states        the states to dispatch
             * @param [tmpStateIds] optional, used e.g. when requestStates is performed.
             * @private
             */
            __dispatchStates: function __dispatchStates(states, tmpStateIds) {
                var promises = [];

                if (tmpStateIds || this._stateHandler.stateIds || this._stateHandler.ignoreIds) {
                    Debug.StateHandler && console.log(this.__dName(), "__dispatchStates (__checkForStateChanges)");
                    promises.push(this.__checkForStateChanges(states, tmpStateIds) || true);
                } else {
                    Debug.StateHandler && console.log(this.__dName(), "__dispatchStates (receivedStates)");
                    promises.push(this.receivedStates(states) || true);
                }

                return Q.all(promises);
            },

            /**
             * Called when the states in the state container did change. it will then check if one of the states specified in the
             * stateIds array at the registration did change. If one of them changed, receivedStates will be called. If not, the
             * state update will be ignored.
             * @param states        the new states.
             * @param tmpStateIds   temporary used e.g. in request states.
             * @private
             */
            __checkForStateChanges: function __checkForStateChanges(states, tmpStateIds) {
                var didChange = this._stateHandler.statesVersion < 0,
                    // force the first update.. (if you register eg. for stateText, and the stateText is always undefined, you should anyway get 1 update)
                    lastStates = this._stateHandler.lastStates,
                    stateIdsToCheck = tmpStateIds || this._stateHandler.stateIds || Object.keys(states),
                    newState,
                    changedIds = {};
                Debug.StateHandler && console.log(this.__dName(), "__checkForStateChanges (v: " + this._stateHandler.statesVersion + ")"); // check if some of the IDs must be ignored.

                if (this._stateHandler.ignoreIds) {
                    stateIdsToCheck = stateIdsToCheck.filter(function (checkId) {
                        return this._stateHandler.ignoreIds.indexOf(checkId) === -1;
                    }.bind(this));
                }

                stateIdsToCheck.forEach(function (id) {
                    // check if there has been a change since the last
                    newState = JSON.stringify(states[id]);

                    if (lastStates[id] !== newState) {
                        // something changed!
                        didChange = true;
                        Debug.StateHandler && console.log(this.name, "   " + id + " - " + lastStates[id] + " -> " + newState);
                        this._stateHandler.lastStates[id] = newState;
                        changedIds[id] = states[id];
                    }
                }.bind(this));
                Debug.StateHandler && didChange && console.log(this.__dName(), " --> states changed, push");

                if (didChange) {
                    return this.receivedStates(states, changedIds) || Q.resolve();
                } else {
                    return Q.resolve();
                }
            },
            __allStatesReceived: function __allStatesReceived(allStatesReceived) {
                if (!allStatesReceived) {
                    this._forceStateUpdate();
                }

                this.allStatesReceived && this.allStatesReceived.apply(this, arguments);
            },
            __dName: function __dName() {
                if (this.control) {
                    return this.control.name + "(" + this.control.uuidAction + ")";
                } else {
                    return this.name;
                }
            }
        };
    }

};
