var PasswordEval = function () {/**
     * Checks if the username and password are part of the forbidden user/pass list.
     * @param username
     * @param password
     * @param forbiddenList
     * @returns Boolean
     */
    var isKnownUserPassword = function isKnownUserPassword(username, password, forbiddenList) {
        if (!forbiddenList) {
            return false;
        } // escape username and password, so no regex control characters are contained.


        var passwordForRegex = password ? escapeRegExp(password) : "";
        var userForRegex = escapeRegExp(username);
        var regex = new RegExp("\n" + userForRegex + "\n( {1}[^ \n]*\n)*( " + passwordForRegex + "\n)+");
        return forbiddenList.search(regex) < 0;
    };
    var evaluatePassword = function evaluatePassword(strPass, nMaxScore) {
        nMaxScore = nMaxScore || 4;
        var nScore = 0,
            nLength = strPass.length,
            achPrevious = ['\0', '\0', '\0', '\0', '\0'],
            i,
            x;

        if (nLength <= 0) {
            return 0; //Password has to have a minimum length
        }

        nScore -= (10 - nLength) * 5;

        for (i = 0; i < nLength; i++) {
            var ch = strPass[i];

            if (isCapitalLetter(ch)) {
                if (!isCapitalLetter(achPrevious[0])) {
                    nScore += 4; //highest Score
                } else if (!isCapitalLetter(achPrevious[1])) {
                    nScore += 2; //lower score if previous char was also capital
                } else {
                    nScore += 1;
                }
            } else if (isLowerLetter(ch)) {
                if (!isCapitalLetter(achPrevious[0])) {
                    nScore += 4; //highest Score
                } else if (!isCapitalLetter(achPrevious[1])) {
                    nScore += 2;
                } else {
                    nScore += 1;
                }
            } else if (isNumber(ch)) {
                if (!isNumber(achPrevious[0])) {
                    nScore += 14; //highest Score
                } else if (!isNumber(achPrevious[1])) {
                    nScore += 10;
                } else {
                    nScore += 8;
                }
            } else {
                if (i === 0 || !isSpecialCharacter(achPrevious[0])) {
                    nScore += 22;
                } else if (!isSpecialCharacter(achPrevious[1])) {
                    nScore += 17;
                } else {
                    nScore += 12;
                }
            }

            var nSame = 0;

            for (x = 0; x < achPrevious.length; x++) {
                if (ch === achPrevious[x]) {
                    nScore -= Math.pow(5, nSame);
                    nSame++;
                }
            }

            for (x = achPrevious.length - 1; x > 0; x--) {
                achPrevious[x] = achPrevious[x - 1];
            }

            achPrevious[0] = ch;
        }

        var nHalfLength = Math.round(nLength / 2);

        for (i = 0; i < nHalfLength; i += 1) {
            for (x = nHalfLength; x > 1; x--) {
                var strPart = strPass.substr(i, x);

                if (strPass.indexOf(strPart, i + 1, nLength - i - 1) > 0) {
                    nScore -= 7 * x;
                    i += x;
                    break;
                }
            }
        }

        nScore = scaleScore(nScore, nMaxScore);
        nScore = Math.round(nScore);
        return nScore;
    };

    var scaleScore = function scaleScore(nScore, nMaxScore) {
        var nInternalMax = 150;

        if (nScore > nInternalMax) {
            return nMaxScore; //internal maximal score;
        }

        if (nScore <= 0) {
            return 0;
        }

        return nScore * nMaxScore / nInternalMax;
    };

    var isSpecialCharacter = function isSpecialCharacter(ch) {
        if (isLetter(ch)) {
            return false;
        }

        return isNumber(ch);
    };

    var isNumber = function isNumber(ch) {
        var n = ch.charCodeAt(0);
        return n >= 48 && n <= 57;
    };

    var isLetter = function isLetter(ch) {
        if (isCapitalLetter(ch)) {
            return true;
        }

        return isLowerLetter(ch);
    };

    var isCapitalLetter = function isCapitalLetter(ch) {
        var n = ch.charCodeAt(0);
        return n >= 65 && n <= 90;
    };

    var isLowerLetter = function isLowerLetter(ch) {
        var n = ch.charCodeAt(0);
        return n >= 97 && n <= 122;
    };

    return {
        evaluatePassword: evaluatePassword,
        isKnownUserPassword: isKnownUserPassword
    };
}();
