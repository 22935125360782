"use strict";

window.CustomRateHandler = {
    get Mixin() {
        return {
            //define mixin's prototype. These will be copied to the prototype of the classes that will use this `mixin`
            _customRateHandler: {},
            updateRate: null,
            nextValue: null,
            nextAction: null,
            resendInterval: null,
            nextPromise: null,
            _updateCustomRate: function _updateCustomRate(rate) {
                this._customRateHandler.updateRate = rate;
            },
            _destroyCustomRateHandler: function _destroyCustomRateHandler() {
                if (this._customRateHandler.resendInterval !== null) {
                    console.warn("CustomRateHandler did not dispatch " + this._customRateHandler.nextAction + " due to being destroyed!");
                    clearTimeout(this._customRateHandler.resendInterval);
                    this._customRateHandler.resendInterval = null;
                }
            },

            /**
             * Checks whether we've got something left to dispatch. if so, it's sent now
             * @private
             */
            _requestPerformAction: function _requestPerformAction(action, value) {
                // Assign the updateRate if it hasn't been assigned yet, default value is 0.25 seconds
                if (this._customRateHandler.updateRate === null) {
                    if (this.config) {
                        this._customRateHandler.updateRate = this.config.updateRate !== null ? this.config.updateRate : 0.25;
                    } else {
                        this._customRateHandler.updateRate = 0.25;
                    }
                } // simply overwrite the previously stored next operation. last come, first serve.
                // only the most recent info is dispatched


                this._customRateHandler.nextAction = action;
                this._customRateHandler.nextValue = value; // if no timer is running, start one and perform the current action instantly

                if (this._customRateHandler.resendInterval === null || this._customRateHandler.resendInterval === undefined) {
                    return this.__performNextAction();
                } else {
                    return this._customRateHandler.nextPromise;
                } // if a timer is running, it will perform the current action as soon as possible

            },
            __performNextAction: function __performNextAction() {
                var def = Q.defer(),
                    promise;

                if (this._customRateHandler.nextAction !== null && this._customRateHandler.nextValue !== null) {
                    promise = this._performAction(this._customRateHandler.nextAction, this._customRateHandler.nextValue) || true;
                    this._customRateHandler.nextAction = null;
                    this._customRateHandler.nextValue = null;
                    this._customRateHandler.resendInterval = setTimeout(function () {
                        def.resolve(this.__performNextAction());
                    }.bind(this), this._customRateHandler.updateRate * 1000);
                    this._customRateHandler.nextPromise = def.promise;
                } else {
                    this._customRateHandler.resendInterval = null;
                    def.resolve();
                    promise = def.promise;
                }

                return promise;
            }
        };
    }

};
