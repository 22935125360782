"use strict";

var Observer = function () {
    var eventMap = {};
    let weakThis = this;
    /**
     * Adds an event listener for a certain event name, also prepares for removing it later on
     * @param evName        the event you want to observe
     * @param listener      the listener to call once the event is emited
     * @returns {Function}  the removeFn that is passed on to .off later on.
     */

    weakThis.on = function (evName, listener) {
        var listeners;
        listeners = eventMap[evName];

        if (!listeners) {
            listeners = eventMap[evName] = [];
        }

        if (listener instanceof Function) {
            listeners.push(listener);
        }

        return function () {
            // when function got called, remove listener!
            eventMap[evName].splice(eventMap[evName].indexOf(listener), 1);
        };
    };

    weakThis.emit = function (evName) {
        var i, eventArgs;
        var emitListeners = eventMap[evName] || [];
        eventArgs = Array.prototype.slice.call(arguments, 1);
        eventArgs.unshift({
            name: evName,
            scope: this
        });

        for (i = 0; i < emitListeners.length; i++) {
            /*setTimeout(function(listener, args) {
                listener.apply(weakThis, args);                     --> causes problems!
            }.bind(weakThis, emitListeners[i], eventArgs));*/
            // NEVER change this to async without checking every single use case!
            try {
                emitListeners[i].apply(weakThis, eventArgs);
            } catch (e) {
                console.error(e.stack);
            }
        }
    };
    /**
     * simply calls the destroyFn which removes the EventListener
     * @param destroyFn
     */


    weakThis.off = function off(destroyFn) {
        destroyFn();
    };
};
